<template>
    <div class="jnpxDetail">
        <div class="item">
            <div class="label">培训名称</div>
            <div class="txt">{{item.name}}</div>
        </div>
        <div class="item">
            <div class="label">培训时间</div>
            <div class="txt">{{ item.time }}</div>
        </div>

        <div class="content">
            <div class="content_title">
                <div class="label">培训详情</div>
                <div class="bottomColor"></div>
            </div>
            <div class="innerContent" v-html="item.info">
<!--                {{ item.info }}-->
            </div>
        </div>

        <div class="remark">
            {{ item.remark }}
        </div>
        <!--        返回home-->
        <div class="reqlist_back_home" v-on:click="toReqList">
            <img src="assets/req_back.png"/>
        </div>
    </div>
</template>

<script>
    document.title="技能培训详情页"
    export default {
        name: "JiNengPeiXunDetail.vue",
        data(){
            return{
                item:{}
            }
        },
        mounted(){
            this.init();
        },
        methods:{
            init(){
                let args=this.$route.params.args;
                this.item=args;
            },
            toReqList(){
                this.$router.push({path:"/px"})
            }
        },
    }
</script>

<style scoped>
    .jnpxDetail{
        background-color: white;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        width:710px;
        border-radius: 10px;
        font-size: 26px;
        letter-spacing: 2px;
        padding-top: 2px;
    }
    .item{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
    }
    .item .label{
        color: #A79F9F;
        margin-left: 30px;
    }
    .item .txt{
        margin-left: 16px;
    }

    .content{
        border-top:2px #E4E4E4 dashed;
        margin-top: 16px;
        border-bottom:2px #E4E4E4 dashed;
    }
    .content .content_title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 28px;
    }
    .content .content_title .label{
        margin-top: 20px;
    }
    .content .content_title .bottomColor{
        margin-top: 14px;
        background-color: #226DF8;
        height: 4px;
        width: 160px;
    }
    .content .innerContent{
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 40px;
        padding-bottom: 16px;
    }

    .remark{
        margin: 16px 30px;
    }

    .reqlist_back_home{
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 100px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .reqlist_back_home img{
        width: 50px;
        height: 50px;
    }


</style>
